<template>
  <div>
    <h1>{{ message }}</h1>
    <h4 v-if="href"><a :href="href">如果长时间未开始下载，请点击此处</a></h4>
    <h4>
      <a-button type="link" @click="gotoMain"
        >寻找其他系统/发行版的版本？</a-button
      >
    </h4>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
import { getUA } from "@/js/getUserUAInfo";
import axios from "axios";
export default {
  components: {
    "a-button": Button,
  },
  methods: {
    gotoMain() {
      this.$router.push("/");
    },
  },
  data() {
    axios.get("/version_info.json").then((res) => {
      const data = {};
      let i = 0;
      for (let platform of res.data.platforms) {
        data[platform.type] = [];
        for (let r of platform.releases) {
          let d = {
            key: String(i++),
            platform: platform.type,
            system: r.name,
            type: r.type,
            x86: r.x86,
            x64: r.x64,
          };
          data[platform.type].push(d);
        }
      }
      const {isWin, isWin64, isLinux64, isIntelMac, isMac, isLinux, isAndroid, isIphone, isIpad} = getUA()
      if (isWin) {
        let href = isWin64
          ? data["Windows"][data["Windows"].length - 1]["x64"]
          : data["Windows"][data["Windows"].length - 1]["x86"];
        if (href) {
          this.href = href;
          location.href = href;
          this.message = "正在下载，请稍候......";
        } else this.message = "无法获取到下载地址，请访问官网下载";
      } else if (isMac) {
        if (isIntelMac) {
          let href = data["MacOS"][data["MacOS"].length - 1]["x64"];
          if (href) {
            this.href = href;
            location.href = href;
            this.message = "正在下载，请稍候......";
          } else this.message = "无法获取到下载地址，请访问官网下载";
        } else this.message = "Mac OS X 系统只支持 Intel CPU";
      } else if (isLinux && !isAndroid) {
        let href = isLinux64
          ? data["Linux"][data["Linux"].length - 1]["x64"]
          : data["Linux"][data["Linux"].length - 1]["x86"];
        if (href) {
          this.href = href;
          location.href = href;
          this.message = "正在下载，请稍候......";
        } else this.message = "无法获取到下载地址，请访问官网下载";
      } else if (isAndroid) {
        this.message = "不支持的系统： Android";
      } else if (isIphone) {
        this.message = "不支持的系统： iPhone";
      } else if (isIpad) {
        this.message = "不支持的系统： iPad";
      } else {
        this.message = "未知系统，请访问官网下载";
      }
    });
    return {
      message: "请稍候......",
      href: "",
    };
  },
};
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
  h1 {
    color: #fcfcfc;
  }
}
</style>